import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLoadEffect } from 'sana/utils';
import { connect, useDispatch } from 'react-redux';
import { updateTokenRecords, getTokenRecords, getThumbnail } from '../behavior/actions';
import { validateTokenStatus } from '../utils/getHttpData';
import { LazyContent } from '../utils/lazyLoader';
import Instagram from './Instagram';
import ErrorMessage from '../components/errorMessage';

import styles from './Instagram.module.scss';

const InstagramFeed = ({
  id,
  model,
  getTokenRecords,
  getTokenData,
  isDesignerMode,
  getThumbnail,
  thumbnailDetails,
}) => {

  const dispatch = useDispatch();
  const [httpLoad, setHttpLoad] = useState(undefined);
  const [response, setResponse] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState('');

  useLoadEffect(() => {
    if (model) {
      dispatch(getTokenRecords(id, model.dataTokenId));
    }
  }, [model.numberOfColumn, model.perPage, model.dataTokenId]);

  const renderElements = getTokenData => {
    const tokenStatus = validateTokenStatus(JSON.parse(getTokenData));
    setHttpLoad(tokenStatus.status);
    if (tokenStatus.status === true) {
      setErrorMessage('');
      getThumbnail(id, JSON.parse(getTokenData).AccessToken);
    }
    else {
      setErrorMessage(tokenStatus.Message);
      console.error('[Token expired]', tokenStatus.Message);
    }
  };

  useEffect(() => {
    if (getTokenData) {
      renderElements(getTokenData);
    }
  }, [getTokenData, model.numberOfColumn, model.perPage]);

  useEffect(()=> {
    if(thumbnailDetails){
      setResponse(thumbnailDetails);
      setHttpLoad(true);
    }
  }, [thumbnailDetails]);

  const renderInstagram = useMemo(() => {
    let $element = '';
    if (response) {
      if (response.status === 'error') {
        console.error('[Token expired]', response.message);
      }
      $element = <Instagram response={response} model={model} isDesignerMode={isDesignerMode} />;
    }
    return $element;
  }, [response, model]);

  return (
    <>
      <LazyContent className={styles.imageLoader} wait={httpLoad}>
        <>
          {renderInstagram}
          {isDesignerMode && errorMessage && <ErrorMessage message={errorMessage} />}
        </>
      </LazyContent>
    </>
  );
};

InstagramFeed.propTypes = {
  model: PropTypes.object,
  id: PropTypes.string.isRequired,
  savedDataField: PropTypes.object,
  getTokenData: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  expired: PropTypes.bool,
  updateTokenRecords: PropTypes.func.isRequired,
  getTokenRecords: PropTypes.func.isRequired,
  isDesignerMode:PropTypes.bool,
};

const mapStateToProps = (props, { id }) => {

  let mapStatus = {
    savedDataField: null,
    getTokenData: undefined,
    expired: undefined,
    thumbnailDetails: null,
  };

  if(props) {
    const { savedDataField, getTokenData, thumbnailDetails } = props;
    mapStatus = {
      savedDataField,
      getTokenData: getTokenData[id],
      expired: null,
      thumbnailDetails: thumbnailDetails[id],
    };
  }
  return mapStatus;
};
export default connect(mapStateToProps, {
  updateTokenRecords,
  getTokenRecords,
  getThumbnail,
})(InstagramFeed);