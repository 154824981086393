import { errorMsg } from './constant';

export const setErrors = (status, result) => {
  if (status === 404) {
    return {
      ...result,
      status: 'error',
      message: result.message ? result.message : errorMsg.invalidUsername,
    };
  } else if (status === 302) {
    return {
      ...result,
      status: 'error',
      message: result.message ? result.message : errorMsg.temporaryRedirect,
    };
  } else {
    return {
      ...result,
      status: 'error',
      message: result.message ? result.message : errorMsg.invalidRequest,
      data: [],
    };
  }
};

export const transformData = inData => {
  const outData = [];
  inData.forEach(element => {
    if (element.media_url)
      outData.push({
        id: element.id,
        media_url: element.media_url,
        thumbnail_url: element.thumbnail_url,
        media_type: element.media_type,
        permalink: element.permalink,
        username: element.username,
        caption: element.caption,
      });
  });
  return outData;
};

export const validateTokenStatus = tokenData => {
  const TokenExpireData = tokenData.ExpiryDate, today = new Date();
  const dateDuration = parseInt(
    (new Date(TokenExpireData) - new Date(today)) /
    (1000 * 60 * 60 * 24),
    10,
  );

  if (dateDuration <= 0) {
    /* Expire Token */
    return {
      status: false,
      Message:
        'Unfortunately, Instagram token has been expired. Please Contact Site Administrate to renew instagram token',
    };
  }
  return { status: true, Message: null };
};