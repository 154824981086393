import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LazyImage } from 'sana/elements';
import styles from './Thumbnail.module.scss';
import { UseSanaTexts } from 'sana/texts';

const Thumbnail = ({ thumbnail_src, alterText }) => {
  function getAlterTextWithPrefix(prefix) {
    if (alterText)
      return `${prefix}, ${alterText}`;
    return undefined;
  }
  return (
    <UseSanaTexts
      textKeys={[
        'DefaultAlterTextForFeedWithoutCaption',
        'PrefixForFeed',
      ]}
    >{([DefaultAlterTextForFeedWithoutCaption, PrefixForFeed]) => {
      return (
        <LazyImage
          src={thumbnail_src}
          alt={getAlterTextWithPrefix(PrefixForFeed) || DefaultAlterTextForFeedWithoutCaption}
          className={classNames(styles.galleryImage, 'thumbnail')}
          draggable="false"
        />
      );
    }}
    </UseSanaTexts>
  );

};

Thumbnail.propTypes = {
  thumbnail_src: PropTypes.string.isRequired,
  alterText: PropTypes.string,
};
export default React.memo(Thumbnail);
