exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\1.45.48.0\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(false);
// imports


// module
exports.push([module.id, ".InstagramFeedContentBlock__Thumbnail_gallery_image{aspect-ratio:1;height:100%;-o-object-fit:cover;object-fit:cover;width:100%}", ""]);

// exports
exports.locals = {
	"gallery_image": "InstagramFeedContentBlock__Thumbnail_gallery_image",
	"galleryImage": "InstagramFeedContentBlock__Thumbnail_gallery_image"
};