exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\1.45.48.0\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(false);
// imports


// module
exports.push([module.id, ".InstagramFeedContentBlock__ErrorMessage_alert_danger{background-color:var(--adk-theme-messages_Error_BackgroundColor);border-radius:5px;color:var(--adk-theme-messages_Error_FontColor);font-size:var(--adk-theme-messages_FontSize);padding:8px}", ""]);

// exports
exports.locals = {
	"alert_danger": "InstagramFeedContentBlock__ErrorMessage_alert_danger",
	"alertDanger": "InstagramFeedContentBlock__ErrorMessage_alert_danger"
};