exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\1.45.48.0\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(false);
// imports


// module
exports.push([module.id, ".InstagramFeedContentBlock__Profile_instagram-link{color:var(--adk-theme-darkestText_FontColor);display:inline-block;font-size:var(--adk-theme-normalText_BiggerFontSize);font-weight:700;height:45px;line-height:24px;padding-top:15px}.InstagramFeedContentBlock__Profile_instagram-link .InstagramFeedContentBlock__Profile_name{margin-left:4px}", ""]);

// exports
exports.locals = {
	"instagram-link": "InstagramFeedContentBlock__Profile_instagram-link",
	"instagramLink": "InstagramFeedContentBlock__Profile_instagram-link",
	"name": "InstagramFeedContentBlock__Profile_name"
};