const PREFIX = 'ADDON/INSTAGRAM/';
export const VERIFY_TOKEN_RECORDS_SAVE_REQUESTED = PREFIX + 'VERIFY_TOKEN_RECORDS_SAVE_REQUESTED';
export const VERIFY_TOKEN_RECORDS_RECORDS_SAVED = PREFIX + 'VERIFY_TOKEN_RECORDS_RECORDS_SAVED';
export const TOKEN_RECORDS_REQUESTED = PREFIX + 'TOKEN_RECORDS_REQUESTED';
export const TOKEN_RECORDS_RECEIVED = PREFIX + 'TOKEN_RECORDS_RECEIVED';
export const THUMBNAIL_REQUEST = PREFIX + 'THUMBNAIL_REQUEST';
export const THUMBNAIL_RECEIVED = PREFIX + 'THUMBNAIL_RECEIVED';

export const updateTokenRecords = (type, records) => ({
  type: VERIFY_TOKEN_RECORDS_SAVE_REQUESTED,
  payload: { type, records },
});
export const tokenRecordsUpdated = (createdIds, updatedIds) => ({
  type: VERIFY_TOKEN_RECORDS_RECORDS_SAVED,
  payload: { createdIds, updatedIds },
});

export const getTokenRecords = (contentBlockId, recordId) => ({
  type: TOKEN_RECORDS_REQUESTED,
  payload: { contentBlockId, recordId },
});

export const tokenRecordsReceived = (contentBlockId, records) => ({
  type: TOKEN_RECORDS_RECEIVED,
  payload: { contentBlockId, records },
});

export const getThumbnail = (contentBlockId, accessToken) => ({
  type: THUMBNAIL_REQUEST,
  payload: { contentBlockId, accessToken },
});

export const thumbnailReceived = (contentBlockId, thumbnailDetails) => ({
  type: THUMBNAIL_RECEIVED,
  payload: { contentBlockId, thumbnailDetails },
});
