import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Icon = props => {
  return <FontAwesomeIcon {...props} />;
};

Icon.propTypes = {
  props: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
  }),
};
export { Icon };
