import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'sana/elements';
import { setFlexSize } from '../utils/common';
import Profile from './profile';
import Thumbnail from './thumbnail';
import ErrorMessage from './errorMessage';
import styles from './Instagram.module.scss';

const Instagram = ({ response, model, isDesignerMode }) => {
  const [numberOfItems, setNumbOfItem] = useState(0);
  const [device, setDevice] = useState('desktop');
  const [listcount, setListCount] = useState(0);
  const isAdminView = window.location.pathname === '/sana-visual-designer';

  useEffect(() => {
    const width = window.innerWidth;
    if (width < 600)
      setDevice('mobile');
    else if (width < 960)
      setDevice('tab');
  }, []);

  useEffect(() => {
    if (device === 'mobile')
      setNumbOfItem(4);
    else if (device === 'tab')
      setNumbOfItem(8);
    else
      setNumbOfItem(model.perPage);
  }, [device, model.perPage]);

  useEffect(() => {
    if (numberOfItems > 0)
      setListCount(numberOfItems);
  }, [numberOfItems]);

  const loadlist = listcount => {
    setListCount(listcount + numberOfItems);
  };

  const renderItems = limit => {
    if (response.data.length > 0) {
      return response.data.slice(0, limit).map(feed => {
        return (
          <Link
            key={feed.id}
            className={classNames(
              'Instagram-feed-gallery',
              styles.galleryItem,
              `${setFlexSize(model.numberOfColumn, styles)}`,
            )}
            href={`${isAdminView ? '#' : feed.permalink}`}
            target={`${isAdminView ? '_self' : '_blank'}`}
            rel="noopener noreferrer"
          >
            <Thumbnail
              thumbnail_src={feed.media_type === 'VIDEO' ? feed.thumbnail_url : feed.media_url}
              alterText={feed.caption}
            />
          </Link>
        );
      });
    } else if(isDesignerMode)
      return <ErrorMessage message={response.message} />;
  };

  return (
    <div className={classNames(styles.gallery, 'instagram-feed-content-block')}>
      {response && renderItems(listcount)}
      <div className={styles.footer}>
        {response && (
          <>
            {response.data.length >= listcount &&
              response.data.length !== listcount &&
              device !== 'desktop' && (
                <span
                  className={classNames(styles.loadMore, 'loadMore')}
                  onClick={() => loadlist(listcount)}
                >
                  Load more
                </span>
              )}
            {response.data.length > 0 && <Profile username={response.data[0].username} />}
          </>
        )}
      </div>
    </div>
  );
};

Instagram.propTypes = {
  response: PropTypes.object,
  model: PropTypes.object,
  isDesignerMode:PropTypes.bool,
};

export default Instagram;