export const setFlexSize = (numberOfColumn, styles) => {
  switch (numberOfColumn) {
    case 1:
      return styles.flex1;
    case 2:
      return styles.flex2;
    case 3:
      return styles.flex3;
    case 4:
      return styles.flex4;
    case 5:
      return styles.flex5;
    case 6:
      return styles.flex6;
    default:
      return styles.flex6;
  }
};