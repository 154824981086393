import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import { concatMap, switchMap , map, pluck, groupBy,mergeMap, catchError } from 'rxjs/operators';
import {
  VERIFY_TOKEN_RECORDS_SAVE_REQUESTED, tokenRecordsUpdated,
  TOKEN_RECORDS_REQUESTED, tokenRecordsReceived,
  THUMBNAIL_REQUEST, thumbnailReceived, THUMBNAIL_RECEIVED,
} from './actions';
import { updateTokenRecordsMutation, getTokenRecordsQuery } from './queries';
import { addonId, RECORD_TYPE, APIExternalUrl } from '../../share/constant';
import { setErrors, transformData } from '../utils/getHttpData';

const epic = (action$, _state$, { api }) => {
  const add$ = action$.pipe(
    ofType(VERIFY_TOKEN_RECORDS_SAVE_REQUESTED),
    concatMap(({ payload: { type: recordType, records } }) =>
      api.graphApi(updateTokenRecordsMutation, { id: addonId, recordType, records }).pipe(
        pluck('addons', 'saveRecords'),
        map(({ createdIds, updatedIds }) => tokenRecordsUpdated(createdIds, updatedIds)),
      ),
    ),
  );
  const listRecords$ = action$.pipe(
    ofType(TOKEN_RECORDS_REQUESTED),
    groupBy(action => action.payload.contentBlockId),
    mergeMap(group => group.pipe(
      switchMap(({ payload: { contentBlockId, recordId } }) => {
        return api.graphApi(getTokenRecordsQuery, { id: addonId, type:RECORD_TYPE, recordId }).pipe(
          pluck('addons', 'records', 'byId'),
          map(records => tokenRecordsReceived(contentBlockId, records)),
        );
      }),
    )),
  );

  const thumbnailRequest$ = action$.pipe(
    ofType(THUMBNAIL_REQUEST),
    concatMap(({ payload: { contentBlockId, accessToken } }) =>
      api.fetch( `${APIExternalUrl}/me/media?fields=id,media_url,thumbnail_url,media_type,permalink,username,caption&access_token=${accessToken}`).pipe(
        map(result => {
          const thumbnailDetails = {
            status: 'done',
            data: transformData(result.data),
            message: '',
          };
          return thumbnailReceived(contentBlockId, thumbnailDetails);
        }),
        catchError(error => {
          const thumbnailDetails = setErrors(error.status, { status: 'error', message: error.response.error.message, data: [] });
          return of({
            type: THUMBNAIL_RECEIVED,
            payload: { contentBlockId, thumbnailDetails },
          });
        }),
      )),
  );

  return merge(add$, listRecords$, thumbnailRequest$);
};
export default epic;