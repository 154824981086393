import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { InstaPermtLink } from '../../utils/constant';
import { UseSanaTexts } from 'sana/texts';
import { Icon } from '../icon';
import styles from './Profile.module.scss';

const Profile = ({ username }) => {
  return (
    <UseSanaTexts
      textKeys={[
        'PrefixForAccountLink',
      ]}
    >{([PrefixForAccountLink]) => {
      return (
        <a
          href={`${InstaPermtLink}/${username}`}
          target="_blank"
          rel="noopener noreferrer"
          className={classNames(styles.instagramLink, 'instagram-profile-link')}
          aria-label={`${PrefixForAccountLink} ${username}`}
        >
          <Icon icon={faInstagram} size="lg" />
          <span className={classNames(styles.name, 'name')}>{`@${username}`}</span>
        </a>
      );
    }}
    </UseSanaTexts>
  );
};

Profile.propTypes = {
  username: PropTypes.string.isRequired,
};
export default React.memo(Profile);
