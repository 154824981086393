exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\1.45.48.0\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(false);
// imports


// module
exports.push([module.id, ".InstagramFeedContentBlock__lazyloader_default-placeholder{background-clip:content-box;background-color:var(--adk-theme-placeholderColor);border-radius:5px;box-sizing:content-box;display:block;height:100%;min-height:1em;width:100%}", ""]);

// exports
exports.locals = {
	"default-placeholder": "InstagramFeedContentBlock__lazyloader_default-placeholder",
	"defaultPlaceholder": "InstagramFeedContentBlock__lazyloader_default-placeholder"
};