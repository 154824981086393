import { VERIFY_TOKEN_RECORDS_RECORDS_SAVED, TOKEN_RECORDS_RECEIVED, THUMBNAIL_RECEIVED } from './actions';

const initialState = {
  savedDataField: {},
  getTokenData: {},
  thumbnailDetails: {},
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case VERIFY_TOKEN_RECORDS_RECORDS_SAVED:
      return {
        ...state,
        savedDataField: payload,
      };
    case TOKEN_RECORDS_RECEIVED:
      const { contentBlockId, records } = payload;
      return {
        ...state,
        getTokenData: {
          ...state.getTokenData,
          [contentBlockId]: records.data,
        },
      };
    case THUMBNAIL_RECEIVED:
      return {
        ...state,
        thumbnailDetails: {
          ...state.thumbnailDetails,
          [payload.contentBlockId]: payload.thumbnailDetails,
        },
      };
    default:
      return state;
  }
}